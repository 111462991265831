import { v4 } from 'uuid';
import { take } from 'lodash';

type User = { id: string; nickname: string; url: string };

export const getDraftText = (text: string) => ({
  type: 'DRAFTJS' as const,
  content: `{"blocks":[{"key":"foo","text":"${text}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{},"documentStyle":{},"VERSION":"8.71.44","ID":"3b1a3552-c01b-4970-9f75-bfa95d35125f"}`,
});

export const getMember = ({ id, nickname, url }: User) => ({
  id,
  status: 'UNKNOWN',
  profile: {
    nickname,
    photo: {
      url,
    },
  },
  privacyStatus: 'UNKNOWN',
  activityStatus: 'UNKNOWN',
  createdDate: '2020-09-02T11:28:30.000Z',
  updatedDate: '2022-07-07T06:18:34.810Z',
});

export const generateComment = ({
  userId,
  text,
  replies,
  replyingToUser,
  reactions,
  repliesToShow,
  rating,
  isMarked = false,
}: {
  userId: string;
  text: { type: 'DRAFTJS'; content: string };
  replies?: any[];
  repliesToShow?: number;
  replyingToUser?: string;
  reactions?: { total: number; votes?: number; users: User[] };
  isMarked?: boolean;
  rating?: number;
}) => {
  const date = (() => {
    const d = new Date();
    d.setDate(d.getDate() - 5);
    return d.toISOString();
  })();

  const { hasMoreReplies, remainingReplies } = (() => {
    let hasMoreReplies = false;
    let remainingReplies = 0;
    if (typeof replies !== 'undefined' && typeof repliesToShow !== 'undefined') {
      hasMoreReplies = replies.length > repliesToShow;
      remainingReplies = hasMoreReplies ? replies.length - repliesToShow : 0;
    }

    return { hasMoreReplies, remainingReplies };
  })();
  return {
    commentId: v4(),
    resourceId: '1',
    comment: {
      text,
      mentions: [],
      attachments: [],
      effects: [],
      resources: [],
    },
    rating,
    reactions: {
      total: reactions?.total ?? 0,
      reactions: reactions?.total
        ? [
            {
              hasReacted: false,
              identities: reactions.users.map((u) => ({
                identityId: u.id,
                identityType: 'MEMBER',
              })),
              reactionCode: ':like:',
              total: reactions.total,
            },
            {
              hasReacted: false,
              identities: reactions.users.map((u) => ({
                identityId: u.id,
                identityType: 'MEMBER',
              })),
              reactionCode: ':haha:',
              total: reactions.total,
            },
            {
              hasReacted: false,
              identities: reactions.users.map((u) => ({
                identityId: u.id,
                identityType: 'MEMBER',
              })),
              reactionCode: ':wow:',
              total: 1,
            },
          ]
        : [],
    },
    replies: {
      total: replies?.length ?? 0,
      comments: replies ? take(replies, repliesToShow ?? replies.length) : [],
      pageCursor:
        'V2.CgExEjQKMjE2NTkwODg0NDM1MjJfODQyODg2ZmMtYzhiYy00YzZiLTlmNWYtMDZjNzg1YjI2ZjM4GgIIASINCgljcmVhdGVkQXQQAUgB',
      ...(hasMoreReplies ? { nextCursor: v4() } : {}),
      paginationContext: {
        offset: 0,
        remainingCount: remainingReplies,
      },
    },
    createdAt: date,
    updatedAt: date,
    availableActions: ['DELETE', 'EDIT', 'MARK', 'REPLY', 'REPORT', 'VIEW'],
    createdBy: { identityId: userId, identityType: 'MEMBER' },
    applicationContext: {
      contextId: '1',
      contextType: 'context-type',
      appDefId: 'd95709a3-ab2b-44ad-b5b6-7103ba0df9f8',
      metaSiteId: '4c5cf66e-7483-46dd-a0df-acf7a880ff3a',
    },
    ...(replyingToUser
      ? {
          replyTo: {
            commentId: 'd95709a3-ab2b-44ad-b5b6-7103ba0df9f8',
            createdBy: {
              identityId: replyingToUser,
              identityType: 'MEMBER',
            },
          },
        }
      : {}),
    repliesCount: replies?.length ?? 0,
    status: 'PUBLISHED',
    isMarked,
    score: reactions?.votes ?? 0,
    upvoteCount: 0,
    downvoteCount: 0,
    reactionsSummary: { total: 0, reactionsCounts: [] },
  };
};
