import {
  Category,
  ReactionTypeType as CommentReactions,
  RepliesReactionTypeType as ReplyReactions,
  Type as MarkedLabelType,
  IconTypeType as ReactionIcon,
  QueryCategoryRequest,
  Category as ApiCategory,
} from '@wix/ambassador-comments-v1-category/types';
import { IHttpClient } from '@wix/yoshi-flow-editor';
import {
  createCategory as apiCreateCategory,
  queryCategory as apiQueryCategory,
} from '@wix/ambassador-comments-v1-category/http';
import { assertFields } from '../../ts-utils';
import { fromServerCategory } from './category-service.mappers';
import { updateCategory as apiUpdateCategory } from '../../api/category-service.api';

export const createCategoryService = ({ httpClient }: { httpClient: IHttpClient }) => {
  const service = {
    createCategory: createCategory(httpClient),
    fetchCategories: fetchCategories(httpClient),
    updateCategory: updateCategory(httpClient),
  };

  return service;
};

export const createCategory = (httpClient: IHttpClient) => async () => {
  const defaults: Partial<Category> = {
    name: 'Comments',
    guestCommenting: true,
    guestReactions: true,
    reactionType: CommentReactions.REACTIONS,
    repliesReactionType: ReplyReactions.REACTIONS,
    mainReaction: { iconType: ReactionIcon.THUMBS_UP },
    ratingsSettings: { ratingsEnabled: false, ratingRequired: false },
    markedCommentLabelType: MarkedLabelType.FEATURED,
  };
  try {
    const { data } = await httpClient.request(apiCreateCategory({ category: { ...defaults } }));
    return data.category?.id ?? undefined;
  } catch (e) {}
};

export const fetchCategories = (httpClient: IHttpClient) =>
  async function (request: QueryCategoryRequest = { query: {} }) {
    const { data } = await httpClient.request(apiQueryCategory(request));
    assertFields(data, ['categories'], 'apiQueryCategory reponse');

    return data.categories.map(fromServerCategory);
  };

export const updateCategory = (httpClient: IHttpClient) =>
  async function ({
    category,
    fieldsUpdating,
  }: {
    category: ApiCategory;
    fieldsUpdating: Omit<ApiCategory, 'revision' | 'id'>;
  }) {
    try {
      const { data } = await httpClient.request(
        apiUpdateCategory({
          category,
          fieldsUpdating,
        }),
      );

      return data;
    } catch (e) {}
  };
