import { FlowEditorSDK, IControllerConfig } from '@wix/yoshi-flow-editor';

const CATEGORY_ID_KEY = 'categoryId';
const IS_RESOURCE_COUPLED_TO_URL_KEY = 'isResourceCoupledToUrl';

export const createPublicDataSelectors = (publicData: IControllerConfig['publicData']) => {
  const componentData = publicData.COMPONENT ?? {};

  return {
    getCategoryId: (): string | undefined => componentData[CATEGORY_ID_KEY],
    getIsResourceCoupledToUrl: (): boolean =>
      componentData[IS_RESOURCE_COUPLED_TO_URL_KEY] ?? false,
  };
};

export const createPublicDataStore = async ({
  editorSDK,
  compId,
}: {
  editorSDK: FlowEditorSDK;
  compId: string;
}) => {
  return {
    ...createPublicDataSetters({ editorSDK, compId }),
    ...(await createPublicDataGetters({ editorSDK, compId })),
  };
};

const createPublicDataGetters = async ({
  compId,
  editorSDK,
}: {
  compId: string;
  editorSDK: FlowEditorSDK;
}): Promise<ReturnType<typeof createPublicDataSelectors>> => {
  const compRef = await editorSDK.document.components.getById('token', {
    id: compId,
  });

  const data = await editorSDK.tpa.data.getAll('token', {
    compRef,
  });
  const publicDataSelectors = createPublicDataSelectors(data);

  return publicDataSelectors;
};

const createPublicDataSetters = ({
  compId,
  editorSDK,
}: {
  compId: string;
  editorSDK: FlowEditorSDK;
}) => {
  const getCompRef = async (compId: string) =>
    editorSDK.document.components.getById('token', {
      id: compId,
    });

  return {
    setIsResourceCoupledToUrl: async () => {
      await editorSDK.document.tpa.data.set('token', {
        compRef: await getCompRef(compId),
        scope: 'COMPONENT',
        key: IS_RESOURCE_COUPLED_TO_URL_KEY,
        value: true,
      });
    },
    setCategoryId: async ({ categoryId }: { categoryId: string }) => {
      await editorSDK.document.tpa.data.set('token', {
        compRef: await getCompRef(compId),
        scope: 'COMPONENT',
        key: CATEGORY_ID_KEY,
        value: categoryId,
      });
    },
  };
};

export type PublicDataStore = ReturnType<typeof createPublicDataStore>;
