import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { getCtxFields } from './controller/get-ctx-fields';
import { resolvePaginationConfig } from './configuration/resolve-pagination-config';
import { isWithVotes } from './configuration/is-with-votes';
import settingsParams from './settingsParams';
import { createSettingsEventHandler } from './common/settings-events/create-settings-events-handler';
import { isWithEmotions } from './configuration/is-with-emotions';
import { getIsBlogComments } from './controller/get-is-blog-comments';
import { createCommentsControllerFlow } from './controller/createCommentsControllerFlow';
import { initCommentsController } from './controller/initCommentsController';
import { getIsDemoMode } from './controller/is-demo-mode';
import { createWidgetStore } from './controller/widget-store';
import { BLOG_APP_DEF_ID } from '~/constants/app-def-ids';

const createController: CreateControllerFn = async ({ flowAPI }) => {
  const { setProps, config, wixCodeApi, appParams } = flowAPI.controllerConfig;
  const settingsEventHandler = createSettingsEventHandler(config.publicData.COMPONENT);
  const isBlogComments = getIsBlogComments(appParams.appDefinitionId);
  const isDemoMode = getIsDemoMode(flowAPI);
  const widgetStore = createWidgetStore({
    isLocked: false,
    resourceId: undefined,
    category: undefined,
  });
  const commentsFlow = await createCommentsControllerFlow({
    flowAPI,
    isDemoMode,
    isBlogComments,
    widgetStore,
  });

  return {
    async pageReady() {
      const widgetState = widgetStore.getState();
      const commentsApi = await initCommentsController(flowAPI, {
        isDemoMode,
        appDefinitionId: isBlogComments ? BLOG_APP_DEF_ID : appParams.appDefinitionId,
      });

      const locationQuery = wixCodeApi.location.query;
      const isDeepLinked =
        decodeURIComponent(locationQuery.resourceId) === widgetStore.getState().resourceId &&
        locationQuery.commentId;

      const fetchComments = async () => {
        const { resourceId, category } = widgetStore.getState();
        if (!resourceId) {
          return;
        }
        // fetchComments type shows that it returns void, but in fact, it returns a promise
        await commentsApi.fetchComments(
          resourceId,
          getCtxFields(category?.id ?? resourceId),
          resolvePaginationConfig({
            settings: {
              sortCommentsBy: flowAPI.settings.get(settingsParams.sortCommentsBy),
              repliesOnLoadMore: flowAPI.settings.get(settingsParams.repliesOnLoadMore),
              commentsOnLoadMore: flowAPI.settings.get(settingsParams.commentsOnLoadMore),
              commentsOnFirstPage: flowAPI.settings.get(settingsParams.commentsOnFirstPage),
              repliesOnFirstPage: flowAPI.settings.get(settingsParams.repliesOnFirstPage),
            },
            withVotes: category ? isWithVotes(category?.reactionType) : false,
            withEmotions: category ? isWithEmotions(category?.reactionType) : false,
          }),
          category ? isWithVotes(category?.reactionType) : false,
          category ? category?.ratingsSettings?.ratingsEnabled : false,
        );
      };

      const fetchDeepLinkComments = async ({ commentId }: { commentId: string }) => {
        const { resourceId, category } = widgetStore.getState();
        if (!resourceId) {
          return;
        }
        // fetchDeepLink type shows that it returns void, but in fact, it returns a promise
        await commentsApi.fetchDeepLink(
          commentId,
          resourceId,
          getCtxFields(category?.id ?? resourceId),
          resolvePaginationConfig({
            settings: {
              sortCommentsBy: flowAPI.settings.get(settingsParams.sortCommentsBy),
              repliesOnLoadMore: flowAPI.settings.get(settingsParams.repliesOnLoadMore),
              commentsOnLoadMore: flowAPI.settings.get(settingsParams.commentsOnLoadMore),
              commentsOnFirstPage: flowAPI.settings.get(settingsParams.commentsOnFirstPage),
              repliesOnFirstPage: flowAPI.settings.get(settingsParams.repliesOnFirstPage),
            },
            withVotes: category ? isWithVotes(category?.reactionType) : false,
            withEmotions: category ? isWithEmotions(category?.reactionType) : false,
          }),
          category ? isWithVotes(category?.reactionType) : false,
          category ? category?.ratingsSettings?.ratingsEnabled : false,
        );
      };

      settingsEventHandler
        .on('server-settings-updated', async () => {
          if (commentsFlow.type === 'DefaultFlow') {
            const category = await commentsFlow.fetchCategory();
            widgetStore.setState({ category });
          }
        })
        .on('view-open-box-settings', async () => {
          const { resourceId } = widgetStore.getState();
          resourceId &&
            commentsApi.affect.tryOpenCommentBox(resourceId, {
              shouldFocus: false,
              shouldScroll: false,
            });
        })
        .on(['view-closed-box-settings', 'exit-open-box-settings'], async () => {
          const { resourceId } = widgetStore.getState();
          resourceId && commentsApi.affect.tryCloseAllCommentBoxes(resourceId);
        })
        .on(['sort-updated', 'pagination-updated'], async () => {
          fetchComments();
        });

      widgetStore.subscribe((newState) => {
        if (newState.resourceId) {
          fetchComments();
        }
        setProps(newState);
      });

      isDeepLinked
        ? await fetchDeepLinkComments({ commentId: decodeURIComponent(locationQuery.commentId) })
        : await fetchComments();

      setProps({
        ctxFields: getCtxFields(widgetState.category?.id ?? widgetState.resourceId),
        isLocked: widgetState.isLocked,
        resourceId: widgetState.resourceId,
        category: widgetState.category,
        devSettingsConfig: {
          forceDoubleCommentBox: locationQuery?.forceDoubleCommentBox,
        },
        fitToContentHeight: true,
        isBlogComments,
      });

      commentsApi.bindStateToSetProps();
    },
    updateConfig: (_, { publicData }) => {
      settingsEventHandler.updateData(publicData.COMPONENT ?? {});
    },
    exports: () => ({
      setResourceId: (resourceId: string) => {
        console.log('setResourceId', resourceId);
        widgetStore.setState({ resourceId });
      },
      setIsLocked: (isLocked: boolean) => {
        widgetStore.setState({ isLocked });
      },
    }),
  };
};

export default createController;
