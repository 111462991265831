import { IHttpClient } from '@wix/yoshi-flow-editor';
import { Category, fetchCategories, SortOrder } from '../../../services/category-service';

export const getSettingsCategory = async ({
  httpClient,
  compId,
  targetCategoryId,
  useCategoryPerCompId,
}: {
  compId: string;
  httpClient: IHttpClient;
  targetCategoryId: string | undefined;
  useCategoryPerCompId: boolean;
}): Promise<Category | undefined> => {
  const categories = await fetchCategories(httpClient)({
    query: { sort: [{ fieldName: 'createdDate', order: SortOrder.ASC }] },
  });
  const category = useCategoryPerCompId
    ? categories.filter((category) => category.id === targetCategoryId)[0] ?? categories[0]
    : categories[0];

  if (!category) {
    throw new Error(`Settings category for ${compId} not found}`);
  }
  return category;
};
