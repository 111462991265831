import { Category } from '~/services/category-service';

type Subscriber<T> = (newState: Partial<T>, currentState: T) => void;

export type WidgetState = {
  resourceId: string | undefined;
  category: Category | undefined;
  isLocked: boolean;
};
export type WidgetStore = ReturnType<typeof createWidgetStore>;

export const createWidgetStore = (initialState: WidgetState) => {
  let state = initialState;
  const subscribers: Subscriber<WidgetState>[] = [];

  return {
    getState: () => state,
    setState: (newState: Partial<WidgetState>) => {
      state = { ...state, ...newState };
      subscribers.map((subscriber) => subscriber(newState, state));
    },
    subscribe: (callback: Subscriber<WidgetState>) => {
      subscribers.push(callback);
    },
  };
};
