import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { registerCommentsWidget } from '@wix/ambassador-comments-categories-v1-comments-widget/http';
import { isPageDynamic as getIsPageDynamic } from './is-page-dynamic';
import { getDynamicSection } from './get-dynamic-section';

export const registerWidget = async (
  flowAPI: ControllerFlowAPI,
  { resourceId, categoryId, pageId }: { resourceId?: string; pageId?: string; categoryId?: string },
) => {
  if (!resourceId || !categoryId || flowAPI.environment.isEditor || flowAPI.environment.isPreview) {
    return;
  }
  const isPageDynamic = await getIsPageDynamic(flowAPI.controllerConfig.wixCodeApi);
  try {
    flowAPI.httpClient.request(
      registerCommentsWidget({
        resourceId,
        categoryId,
        componentId: flowAPI.controllerConfig.compId,
        pageId,
        ...(isPageDynamic
          ? { dynamicPageTitle: getDynamicSection(flowAPI.controllerConfig.wixCodeApi.location) }
          : {}),
      }),
    );
  } catch (e) {
    flowAPI.sentry.captureException(e, { tags: { origin: 'registerWidget' } });
  }
};
