import { Mocks } from '@wix/comments-ooi-client/controller';
import { generateComment, getDraftText, getMember } from './utils';
import { take } from 'lodash';
import { v4 } from 'uuid';
import { CommentSortOptions } from '../../common/common-types';

// @TODO add current member?

export const getDemoContent = ({
  baseUrl,
  t,
}: {
  baseUrl: string;
  t: (key: string) => string;
}): Mocks['fetchComments'] => {
  const BASE_URL = baseUrl ? `${baseUrl}/assets/` : '';

  const generateDemoContent = ({
    sortBy,
    commentsToShow,
    repliesToShow,
  }: {
    sortBy: CommentSortOptions;
    commentsToShow?: number;
    repliesToShow?: number;
  }) => {
    const Users = {
      commenter01: {
        id: '4a14f082-0da5-11ed-861d-0242ac120002',
        nickname: t('demoContent.commenterName'),
        url: `${BASE_URL}demo/avatar_1.jpg`,
      },
      commenter02: {
        id: '4a14f258-0da5-11ed-861d-0242ac120002',
        nickname: t('demoContent.commenterName'),
        url: `${BASE_URL}demo/avatar_2.jpg`,
      },
      replier01: {
        id: '4a14f41a-0da5-11ed-861d-0242ac120002',
        nickname: t('demoContent.replierName'),
        url: `${BASE_URL}demo/avatar_4.jpg`,
      },
      replier02: {
        id: '4a14f500-0da5-11ed-861d-0242ac120002',
        nickname: t('demoContent.replierName'),
        url: `${BASE_URL}demo/avatar_5.jpg`,
      },
      replier03: {
        id: '4a14f33e-0da5-11ed-861d-0242ac120002',
        nickname: t('demoContent.replierName'),
        url: `${BASE_URL}demo/avatar_3.jpg`,
      },
    };

    const comments = [
      (() => {
        const userId = Users.commenter01.id;
        return generateComment({
          userId,
          text: getDraftText(t('demoContent.comment01')),
          isMarked: true,
          reactions: {
            total: 14,
            votes: 10,
            users: [Users.replier01, Users.replier02, Users.replier03],
          },
          repliesToShow,
          rating: 5,
          replies: [
            generateComment({
              userId: Users.replier02.id,
              text: getDraftText(t('demoContent.reply02')),
              replyingToUser: Users.replier01.id,
              reactions: {
                total: 3,
                users: [Users.commenter02],
              },
            }),
            generateComment({
              userId: Users.replier03.id,
              text: getDraftText(t('demoContent.reply01')),
              replyingToUser: userId,
              reactions: {
                total: 10,
                users: [Users.replier01, Users.commenter01],
              },
            }),
            generateComment({
              userId: Users.replier02.id,
              text: getDraftText(t('demoContent.reply01')),
              replyingToUser: userId,
            }),
          ],
        });
      })(),
      generateComment({
        userId: Users.commenter02.id,
        text: getDraftText(t('demoContent.comment02')),
        rating: 3,
      }),
    ];

    return {
      comments: {
        comments: take(
          sortBy === CommentSortOptions.oldest ? comments.reverse() : comments,
          commentsToShow,
        ),
        total: 5,
        availableActions: ['ADD_COMMENT', 'VIEW'],
        paginationContext: {
          offset: 0,
          remainingCount: commentsToShow ? comments.length - commentsToShow : 0,
        },
        contextToken:
          'JWT.V2.eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IndpeC5jdHguMSJ9.eyJleHAiOjE2NTkwODg3NTAsImlhdCI6MTY1OTA4ODQ1MCwiYXV0aG9yaXplciI6ImQ5NTcwOWEzLWFiMmItNDRhZC1iNWI2LTcxMDNiYTBkZjlmOCIsImNvbnRleHRJZCI6IjEiLCJjb250ZXh0VHlwZSI6ImNvbnRleHQtdHlwZSIsInBheWxvYWRzIjp7InR5cGUuZ29vZ2xlYXBpcy5jb20vd2l4LmNvbW1lbnRzLkNvbW1lbnRzQWNjZXNzIjoiQ2dFQUVnVUJBd0FFQmc9PSIsInR5cGUuZ29vZ2xlYXBpcy5jb20vd2l4LnJlYWN0aW9ucy5SZWFjdGlvbnNBY2Nlc3MiOiJDQUE9In0sImlkZW50aXR5SGFzaCI6IlljNE5vOC9ZRjNGRklUVU1LRDVxMVJzK3c4Wm56YWVaazRFcWxhd2VneDg9In0.F72zTTZ7PoSor4mPq2HPcdh_hUjT98Sskinsu7crrL9dOqJmyO1yPKiFfY8RmkdUhw-GhoauEhDQtx2cqin6rbl2dHgtLCcIvBLikCPleIK2M-H6F2EUQ5NYuwK_R5J5GAPYW9GUXPn2cKDSktNj6bzHZ8PfkZv7QLeQrRvh9dRkumvOdaPM4d_VeKxgpbl-tlm4SYBrWLAt2KFVNkLNhKESJ4PGk17jxz5W-zkAXjk2r21oUMAz_T5lloyfqgUjMqp0YuOjXMITRMW9HB3MN8eHX0Wjmqx3kv56lLznmmO8eKxFHxrOvDSQ2klxZhcOvXmHGbl25K7l9RvUAiSr_g',
        ...(commentsToShow && commentsToShow < comments.length ? { nextCursor: v4() } : {}),
      },
      registrationData: {
        registrationOpened: true,
        registrationManuallyApproved: false,
        requireEmailVerification: false,
      },
      followedMembers: [],
      ratingsSummary: { total: 4, breakdown: { 1: 1, 2: 0, 3: 2, 4: 0, 5: 1 } },
      totalComments:
        comments.length + comments.reduce((sum, comment) => comment.replies.total + sum, 0),
      members: [
        getMember(Users.commenter01),
        getMember(Users.commenter02),
        getMember(Users.replier03),
        getMember(Users.replier01),
        getMember(Users.replier02),
      ],
      memberRoles: { [Users.commenter01.id]: ['OWNER'] },
      memberBadges: baseUrl
        ? {
            [Users.commenter01.id]: [
              {
                id: '2c56aff6-3856-4e15-8546-659abca10a58',
                title: 'Unicorn Badge',
                textColor: '#FFFFFF',
                icon: `${baseUrl}/assets/demo/unicorn-badge.svg`,
              },
            ],
          }
        : {},
    };
  };

  return async (req) => {
    return generateDemoContent({
      sortBy:
        req.pagingContextSetup?.commentSorting?.[0].order === 'ASC'
          ? CommentSortOptions.oldest
          : CommentSortOptions.newest,
      commentsToShow: req.pagingContextSetup?.limit,
      repliesToShow: req.repliesLimit,
    });
  };
};
